export class BrowserCheck {
  static isOldBrowser(): boolean {
    const ua = window.navigator.userAgent;
    const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    const webkit = !!ua.match(/WebKit/i);
    const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
    
    if (iOSSafari) {
      const version = parseInt((ua.match(/OS (\d+)_/i) || [])[1], 10);
      return version < 11;
    }
    
    return false;
  }

  static applyOldBrowserFixes(): void {
    if (this.isOldBrowser()) {
      // Add any specific fixes for old browsers
      // For example, disable certain animations or features
      document.body.classList.add('old-browser');
    }
  }
} 